@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cm-editor.cm-focused {
  outline: none !important;
}

@layer components {
}

/* Markdown */

.ProseMirror {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #333;
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica, "Segoe UI", Arial, freesans,
    sans-serif;
  font-size: 16px;
  /* line-height: 1.6; */
  word-wrap: break-word;
}

.ProseMirror img {
  border: 0;
}

.ProseMirror hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

.ProseMirror pre {
  overflow: auto;
}

.ProseMirror code,
.ProseMirror kbd,
.ProseMirror pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

.ProseMirror input {
  color: inherit;
  font: inherit;
  margin: 0;
}

.ProseMirror html input[disabled] {
  cursor: default;
}

.ProseMirror input {
  line-height: normal;
}

.ProseMirror input[type="checkbox"] {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.ProseMirror table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ProseMirror td,
.ProseMirror th {
  padding: 0;
}

.ProseMirror * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ProseMirror input {
  font: 13px/1.4 Helvetica, arial, freesans, clean, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.ProseMirror a {
  color: #4183c4;
  text-decoration: none;
}

.ProseMirror a:hover,
.ProseMirror a:focus,
.ProseMirror a:active {
  text-decoration: underline;
}

.ProseMirror hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #ddd;
}

.ProseMirror hr:before {
  display: table;
  content: "";
}

.ProseMirror hr:after {
  display: table;
  clear: both;
  content: "";
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.1;
}

/* .ProseMirror h1 {
  font-size: 30px;
}

.ProseMirror h2 {
  font-size: 21px;
}

.ProseMirror h3 {
  font-size: 16px;
}

.ProseMirror h4 {
  font-size: 14px;
}

.ProseMirror h5 {
  font-size: 12px;
}

.ProseMirror h6 {
  font-size: 11px;
} */

.ProseMirror blockquote {
  margin: 0;
}

.ProseMirror ul {
  list-style-type: initial;
}
.ProseMirror ul ul {
  list-style-type: circle;
}
.ProseMirror ul ul ul {
  list-style-type: square;
}
.ProseMirror ol {
  list-style-type: numeric;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ProseMirror > ul,
.ProseMirror > ol {
  padding: 0;
  margin-top: 0;
  margin-bottom: 12px;
}

.ProseMirror ol ol,
.ProseMirror ul ol {
  list-style-type: lower-roman;
}

.ProseMirror ul ul ol,
.ProseMirror ul ol ol,
.ProseMirror ol ul ol,
.ProseMirror ol ol ol {
  list-style-type: lower-alpha;
}

.ProseMirror dd {
  margin-left: 0;
}

.ProseMirror code {
  font: 12px Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.ProseMirror pre {
  margin-top: 0;
  margin-bottom: 0;
  font: 12px Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.ProseMirror > *:first-child {
  margin-top: 0 !important;
}

.ProseMirror > *:last-child {
  margin-bottom: 0 !important;
}

.ProseMirror .anchor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  padding-right: 6px;
  padding-left: 30px;
  margin-left: -30px;
}

.ProseMirror .anchor:focus {
  outline: none;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  position: relative;
  margin-top: 1em;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1.4;
}

.ProseMirror h1 {
  padding-bottom: 0.3em;
  font-size: 2em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}

.ProseMirror h2 {
  padding-bottom: 0.3em;
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

.ProseMirror h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

.ProseMirror h4 {
  font-size: 1.25em;
}

.ProseMirror h5 {
  font-size: 1em;
}

.ProseMirror h6 {
  font-size: 1em;
  color: #777;
}

.ProseMirror p,
.ProseMirror blockquote,
.ProseMirror ul,
.ProseMirror ol,
.ProseMirror dl,
.ProseMirror table,
.ProseMirror pre {
  margin-top: 0;
  /* margin-bottom: 8px; */
}

.ProseMirror hr {
  height: 4px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-left: 2em;
}

.ProseMirror ul ul,
.ProseMirror ul ol,
.ProseMirror ol ol,
.ProseMirror ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.ProseMirror li > p {
  /* margin-top: 16px; */
}

.ProseMirror dl {
  padding: 0;
}

.ProseMirror dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
}

.ProseMirror dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.ProseMirror blockquote {
  padding: 0 15px;
  color: #777;
  border-left: 4px solid #ddd;
}

.ProseMirror blockquote > :first-child {
  margin-top: 0;
}

.ProseMirror blockquote > :last-child {
  margin-bottom: 0;
}

.ProseMirror table {
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
}

.ProseMirror table th {
  font-weight: bold;
}

.ProseMirror table th,
.ProseMirror table td {
  padding: 6px 13px;
  border: 1px solid #ddd;
}

.ProseMirror table tr {
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.ProseMirror table tr:nth-child(2n) {
  background-color: #f8f8f8;
}

.ProseMirror img {
  max-width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ProseMirror code {
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.ProseMirror code:before,
.ProseMirror code:after {
  letter-spacing: -0.2em;
  content: "\00a0";
}

.ProseMirror pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.ProseMirror .highlight {
  margin-bottom: 16px;
}

.ProseMirror .highlight pre,
.ProseMirror pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border-radius: 3px;
}

.ProseMirror .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.ProseMirror pre {
  word-wrap: normal;
}

.ProseMirror pre code {
  display: inline;
  max-width: initial;
  padding: 0;
  margin: 0;
  overflow: initial;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.ProseMirror pre code:before,
.ProseMirror pre code:after {
  content: normal;
}

.ProseMirror .highlight {
  background: #fff;
}

.ProseMirror .highlight .mf,
.ProseMirror .highlight .mh,
.ProseMirror .highlight .mi,
.ProseMirror .highlight .mo,
.ProseMirror .highlight .il,
.ProseMirror .highlight .m {
  color: #945277;
}

.ProseMirror .highlight .s,
.ProseMirror .highlight .sb,
.ProseMirror .highlight .sc,
.ProseMirror .highlight .sd,
.ProseMirror .highlight .s2,
.ProseMirror .highlight .se,
.ProseMirror .highlight .sh,
.ProseMirror .highlight .si,
.ProseMirror .highlight .sx,
.ProseMirror .highlight .s1 {
  color: #df5000;
}

.ProseMirror .highlight .kc,
.ProseMirror .highlight .kd,
.ProseMirror .highlight .kn,
.ProseMirror .highlight .kp,
.ProseMirror .highlight .kr,
.ProseMirror .highlight .kt,
.ProseMirror .highlight .k,
.ProseMirror .highlight .o {
  font-weight: bold;
}

.ProseMirror .highlight .kt {
  color: #458;
}

.ProseMirror .highlight .c,
.ProseMirror .highlight .cm,
.ProseMirror .highlight .c1 {
  color: #998;
  font-style: italic;
}

.ProseMirror .highlight .cp,
.ProseMirror .highlight .cs {
  color: #999;
  font-weight: bold;
}

.ProseMirror .highlight .cs {
  font-style: italic;
}

.ProseMirror .highlight .n {
  color: #333;
}

.ProseMirror .highlight .na,
.ProseMirror .highlight .nv,
.ProseMirror .highlight .vc,
.ProseMirror .highlight .vg,
.ProseMirror .highlight .vi {
  color: #008080;
}

.ProseMirror .highlight .nb {
  color: #0086b3;
}

.ProseMirror .highlight .nc {
  color: #458;
  font-weight: bold;
}

.ProseMirror .highlight .no {
  color: #094e99;
}

.ProseMirror .highlight .ni {
  color: #800080;
}

.ProseMirror .highlight .ne {
  color: #990000;
  font-weight: bold;
}

.ProseMirror .highlight .nf {
  color: #945277;
  font-weight: bold;
}

.ProseMirror .highlight .nn {
  color: #555;
}

.ProseMirror .highlight .nt {
  color: #000080;
}

.ProseMirror .highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}

.ProseMirror .highlight .gd {
  color: #000;
  background-color: #fdd;
}

.ProseMirror .highlight .gd .x {
  color: #000;
  background-color: #faa;
}

.ProseMirror .highlight .ge {
  font-style: italic;
}

.ProseMirror .highlight .gr {
  color: #aa0000;
}

.ProseMirror .highlight .gh {
  color: #999;
}

.ProseMirror .highlight .gi {
  color: #000;
  background-color: #dfd;
}

.ProseMirror .highlight .gi .x {
  color: #000;
  background-color: #afa;
}

.ProseMirror .highlight .go {
  color: #888;
}

.ProseMirror .highlight .gp {
  color: #555;
}

.ProseMirror .highlight .gs {
  font-weight: bold;
}

.ProseMirror .highlight .gu {
  color: #800080;
  font-weight: bold;
}

.ProseMirror .highlight .gt {
  color: #aa0000;
}

.ProseMirror .highlight .ow {
  font-weight: bold;
}

.ProseMirror .highlight .w {
  color: #bbb;
}

.ProseMirror .highlight .sr {
  color: #017936;
}

.ProseMirror .highlight .ss {
  color: #8b467f;
}

.ProseMirror .highlight .bp {
  color: #999;
}

.ProseMirror .highlight .gc {
  color: #999;
  background-color: #eaf2f5;
}

.ProseMirror .octicon {
  font: normal normal 16px octicons-anchor;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ProseMirror .octicon-link:before {
  content: "\f05c";
}

.ProseMirror .task-list-item {
  list-style-type: none;
}

.ProseMirror .task-list-item + .task-list-item {
  margin-top: 3px;
}

.ProseMirror .task-list-item input {
  float: left;
  margin: 0.3em 0 0.25em -1.6em;
  vertical-align: middle;
}
